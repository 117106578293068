import '@popperjs/core';
// import 'bootstrap';
import 'flowbite'
// import "choices.js/public/assets/styles/choices.css";
import Choices from "choices.js";


window.htmx = require("htmx.org");

document.addEventListener("DOMContentLoaded", function () {
  const ids = ["id_brand"]; 
  ids.forEach(function (id) {
    const element = document.querySelector(`#${id}`);
    if (element) {
      new Choices(element, {
        searchEnabled: true,
        itemSelectText: "",
        noResultsText: "No se encontraron resultados",
        noChoicesText: "No hay opciones disponibles",
        removeItemButton: true,
      });
    }
  });
});
